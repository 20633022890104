import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const recipeRescue2PageData: GamePageData = {
    metaPageTitle: "Recipe Rescue 2 Match-3 Puzzle Game - iDream Interactive",
    metaKeywords: "recipe rescue, recipe rescue game, how to play recipe rescue",
    metaDescription: "Experience Recipe Rescue in a fresh way!  Learn more about our second Recipe Rescue match-3 puzzle game.",
    pageTitle: "Recipe Rescue 2",
    descriptionTitle: "Experience Recipe Rescue in a fresh way!",
    description: <>
        <p>Grab those aprons, and help Nina Nutmeg spice up those recipes!</p>
        <p>Explore hundreds of new levels, maps, and new mechanics. Unlock new areas to serve customers in! Spice up veggies for double the reward!</p>
        <p>Complete levels to serve customers and receive BIG rewards!</p>
        <p>Recipe Rescue 2 was released in 2017, as a sequel to the original Recipe Rescue. We used this as an opportunity to design an automated Chibi generator, which helped us create hundreds of unique Chibis used in the game.</p>
    </>,
    links: [
    ]
}
